import React from 'react'

import Intro from './Components/Intro';
import TimeDown from './Components/TimeDown';
import Aboutus from './Components/Aboutus';
import WhereAndWhen from './Components/WhereAndWhen';
import GoogleMap from './Components/GoogleMap';
import Gallery from './Components/Gallery';
import Attendency from './Components/Attendency';
import Logos from './Components/Logos';
import VideoWedding from './Components/VideoWedding';
import OurStory from './Components/OurStory';

const Homepage = () => {
  return (
    <div>
      
      <Intro/>
      <OurStory />
      <TimeDown/>
      <Aboutus/>
      <Gallery />
      <VideoWedding/>
      <WhereAndWhen/>
      <GoogleMap />
      <Attendency />
      <Logos/>
     
    </div>
  )
}

export default Homepage
