import React from 'react'

const Attendency = () => {
  return (
    <section className="probootstrap-section probootstrap-border-top" data-section="rsvp">
      <div className="container">
        <div className="row mb40">
          <div className="col-md-12 text-center">
            <h2 className="mt0 mb10 probootstrap-animate">Will you attend?</h2>
            <p>Please sign your RSVP</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="probootstrap-card probootstrap-animate">
              <form action="https://formspree.io/ferlinpieter@gmail.com" method="POST" className="probootstrap-form">
              <div className="form-group">
                  <label htmlFor="email">Your Email*</label>
                  <input name="Email" type="email" className="form-control" id="email" placeholder="Your Email"/>
                </div>
                <div className="form-group">
                  <label htmlFor="name">First Name*</label>
                  <input name="First Name" type="text" className="form-control" id="name" placeholder="Your First Name"/>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Last Name*</label>
                  <input name="Last name" type="text" className="form-control" id="name" placeholder="Your Last Name"/>
                </div>
                <div className="form-group">
                  <p>Will you attend?*</p>
                  <label htmlFor="yes"><input name="Attending" type="checkbox" id="yes" value="Yes, I will be there"/> Yes, I will be there</label> <br/>
                  <label htmlFor="no"><input name="Attending"type="checkbox" id="no" value="Sorry, I can't come"/> Sorry, I can't come</label> <br/>
                </div>
                
                <div className="form-group">
                  <label htmlFor="name">Who else is coming with you?</label>
                  <input name="Partner" type="text" className="form-control" id="Partner" placeholder="If some one is atteding with you"/>
                </div>
                <div className="form-group">
                  <p>We would like to be sustainable for the environment. But would you still like to receive the invitation card?*</p>
                  <label htmlFor="yes"><input name="Receiving the invitation card" type="checkbox" id="yes" value="Yes, I want it"/> Yes, I want it</label> <br/>
                  <label htmlFor="no"><input name="Receiving the invitation card" type="checkbox" id="no" value="No, Thanks"/>No, Thanks</label> <br/>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Your Address</label>
                  <input name="Address" type="text" className="form-control" id="Address" placeholder="If you wish to receive the invitation card"/>
                </div>
                <div className="form-group">
                  <label htmlFor="note">Note</label>
                  <textarea name="note" id="note" cols="20" rows="5" 
                    className="form-control" placeholder="Your Note" required/>
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary btn-lg" value="Send RSVP"/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Attendency
