import React from 'react'

const GoogleMap = () => {
  const denhaagWedding="https://maps.google.com/maps?q=Kerkeboslaan%202%2C%202243%20CM%20Wassenaar&t=&z=11&ie=UTF8&iwloc=&output=embed"
  // const franceWedding="https://maps.google.com/maps?q=moorish%20villa%20cavalire&t=&z=13&ie=UTF8&iwloc=&output=embed"
  return (
    <div className="mapouter">
        <div className="gmap_canvas">
            <iframe width={100 + "%"} height="500" id="gmap_canvas" className="map" title="map"
                src={denhaagWedding}
                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
            </iframe>
            {/* <a href="https://www.emojilib.com" alt="maps"></a> */}
        </div>
    </div>

    
  )
}

export default GoogleMap
