import React, { Component } from 'react';
import './App.css';
import ValidateRoute from './ValidateRoute';
import Nav from '../Components/Commen/Nav';
import Footer from '../Components/Commen/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Nav/>
          <ValidateRoute />
        <Footer />
      </div>
    );
  }
}

export default App;
