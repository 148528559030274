import React from 'react'
import introPic from "../../Config/introPic"
const Intro = () => {
    const slides = introPic.map((value,index)=>{
        let data;
        if(index === 0) data="true"
        return (
            <li key={index} style={{backgroundImage: `url(${value.pic})`}} className={value.class}
            data={data}></li>
        )
    })
  return (
    <section className="flexslider" data-section="home">
      <div className="container text-intro-wrap">
        <div className="row" id="intro">
          <div className="col-md-12 text-center text-intro probootstrap-animate">
            <h1> Ferlin<em>&amp;</em>Pieter </h1>
            <h2>Getting Married on June 2019</h2>
          </div>
        </div>
      </div>
      <ul className="slides">
      {slides}
      </ul>
    </section>
  )
}

export default Intro
