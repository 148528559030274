import React from 'react'

const WhereAndWhen = () => {
  return (
    <div className="probootstrap-section probootstrap-section-colored" data-section="when-where">
      <div className="container">
        <div className="row mb40 text-center">
          <div className="col-md-12 probootstrap-animate"><h2 className="mt0">The Weddings</h2></div>
        </div>
        <div className="row">
          <div className="col-md-6 probootstrap-animate">  
            <p><img src="/template/img/ferlin/5.jpg" alt="Free Bootstrap Template by uicookies.com" 
            className="img-responsive img-rounded"/></p>
            <h3>Municipality Wedding </h3>
            <p>Pieter & Ferlin Municipality Wedding on 11th of  February 2019 at Rotterdam Library</p>
    
          </div>
          <div className="col-md-6 probootstrap-animate">
            <p><img src="/template/img/ferlin/6.jpg" alt="Free Bootstrap Template by uicookies.com" 
            className="img-responsive img-rounded"/></p>
            <h3>Côte d'azur Wedding</h3>
            <p>Côte d'azur (South of France) on 8th June 2019, an "intimate" Wedding ceremony and party for family and close friends</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-centered probootstrap-animate">  
            <p><img src="/template/img/ferlin/wassenaar.jpeg" alt="Free Bootstrap Template by uicookies.com" 
            className="img-responsive img-rounded"/>
            In the picture, a historic Sundanese Wedding ceremony, photo collection of the Volkenkunde Museum, Leiden</p>
            <h3>Wassenaar Ceremony </h3>
            <p>The wedding ceremony a is a cultural event and a tribute to the traditions,
               folklore of “Sundanese” culture, which comes from Bandung City in West-Java,
                Indonesia where the bride (Ferlin) was born.</p>
              <p>
              We are very honored as the cultural event will take place at the residence of the Ambassador for 
              The Republic of Indonesia for The Kingdom of The Netherlands, H.E. I Gusti Wesaka Puja.</p>
              <p>We have sent the details trough your email. Guests are welcome with partners,
                 please kindly RSVP below before the 5th of June 2019.</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default WhereAndWhen
