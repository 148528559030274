import React from 'react'
import { BrowserRouter, Route} from 'react-router-dom';
import Homepage from '../Components/Pages/Homepage';
const ValidateRoute = () => {
  return (
    <BrowserRouter>
        <div>
            <Route exact path="/" component={Homepage}/>
        </div>
    </BrowserRouter>
  )
}

export default ValidateRoute
