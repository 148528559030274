import React from 'react'
// import gallaryConfig from "../../Config/gallaryConfig"
const Gallery = () => {
  // const gallariesPhoto = gallaryConfig.map((value,index)=>{
  //   return (
  //     <div className="col-md-6 probootstrap-animate">
  //       <a className="probootstrap-image image-popup" 
  //           href={`/template/img/ferlin/${value}`} 
  //           style={{backgroundImage: `url(/template/img/ferlin/${value})`}}>
  //         <div className="probootstrap-image-for-popup">
  //           <img src={`/template/img/ferlin/${value}`} 
  //           alt="pic" className="img-responsive"/>
  //       </div>
  //       </a>
  //     </div>
  //   )
  // })
  return (
    <section className="probootstrap-section" id="gallery"data-section="gallery">
      <div className="container">
        <div className="row mb40 probootstrap-animate">
          <div className="col-md-12 text-center"><h2>Gallery</h2></div>
        </div>

        <div className="row probootstrap-gutter30">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="row probootstrap-gutter30">
              <div className="col-md-6 probootstrap-animate">
                <a className="probootstrap-image image-popup" href="template/img/ferlin/gallery1.jpg" style={{backgroundImage: "url(template/img/ferlin/gallery1.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="template/img/ferlin/gallery1.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
              <div className="col-md-6 probootstrap-animate">
                <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery2.jpg" style={{backgroundImage: "url(template/img/ferlin/gallery2.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="template/img/ferlin/gallery2.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
            </div>
            <div className="row probootstrap-gutter30">
              <div className="col-md-12 probootstrap-animate">
                <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery3.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery3.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery3.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="row probootstrap-gutter30">
              <div className="col-md-6">
                <div className="row probootstrap-gutter30">
                  <div className="col-md-12 probootstrap-animate">
                    <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery4.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery4.jpg)"}}>
                      <div className="probootstrap-image-for-popup"><img src="template/img/ferlin/gallery4.jpg" alt="pic" className="img-responsive"/></div>
                    </a>
                  </div>
                  <div className="col-md-12 probootstrap-animate">
                    <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery12.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery12.jpg)"}}>
                      <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery12.jpg" alt="pic" className="img-responsive"/></div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 probootstrap-animate">
                <a className="probootstrap-image  height-2x image-popup" href="/template/img/ferlin/gallery6.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery6.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery6.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        

        <div className="row probootstrap-gutter30">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="row probootstrap-gutter30">
              <div className="col-md-6">
                <div className="row probootstrap-gutter30">
                  <div className="col-md-12 probootstrap-animate">
                    <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery7.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery7.jpg)"}}>
                      <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery7.jpg" alt="pic" className="img-responsive"/></div>
                    </a>
                  </div>
                  <div className="col-md-12 probootstrap-animate">
                    <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery8.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery8.jpg)"}}>
                      <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery8.jpg" alt="pic" className="img-responsive"/></div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 probootstrap-animate">
                <a className="probootstrap-image  height-2x image-popup" href="/template/img/ferlin/gallery17.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery17.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery17.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="row probootstrap-gutter30">
              <div className="col-md-6 probootstrap-animate">
                <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery10.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery10.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery10.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
              <div className="col-md-6 probootstrap-animate">
                <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery11.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery11.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery11.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
            </div>
            <div className="row probootstrap-gutter30">
              <div className="col-md-12 probootstrap-animate">
                <a className="probootstrap-image image-popup" href="/template/img/ferlin/gallery16.jpg" style={{backgroundImage: "url(/template/img/ferlin/gallery16.jpg)"}}>
                  <div className="probootstrap-image-for-popup"><img src="/template/img/ferlin/gallery16.jpg" alt="pic" className="img-responsive"/></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery
