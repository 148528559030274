import React from 'react'
const Logos = () => {
  return (
    <section className="probootstrap-section probootstrap-section-colored-darker">
    <div className="container text-center">
      <div className="row">
        <div className="col-md-12 probootstrap-animate"><h2 className="mt0">Thanks to</h2></div>
      </div>
      <div className="row">
  
        <div  className="col probootstrap-animate">
          <a href="http://www.codematrix.nl" className="btn-register"  target="_blank" rel="noopener noreferrer">
            <img src="/template/img/logos/logo.png" alt=""
              className="img-responsive"/></a>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Logos
