import React from 'react'
import "../../../../node_modules/video-react/dist/video-react.css";

import { Player } from 'video-react';

const VideoWedding = props => {
  return (
    // <video className="video" controls autoplay>
    //   <source src="/template/img/ferlin/engagament.mp4" type="video/mp4"/>
    // </video>
    <div>
     <Player 
     playsInline
     poster="/template/img/ferlin/gallery15.jpeg"
     src="/template/img/ferlin/engagament.mp4" />
     <h4>(Video) Pieter and Ferlin Engagement party in Chinese Tradition culture 
       at Verona Palace Hotel, Bandung, Indonesia , 2nd of September 2018 </h4>
     </div>
  
  )
}


export default VideoWedding
