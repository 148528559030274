import React from 'react'

const TimeDown = () => {
  return (
    <section className="probootstrap-section probootstrap-section-colored probootstrap-wedding-countdown">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center probootstrap-animate"><h4>The Wedding Begins In</h4></div>
          <div className="col-md-12 probootstrap-animate">
            <div className="date-countdown"></div>  
          </div>
        </div>
      </div>
    </section>
  )
}

export default TimeDown
