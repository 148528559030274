import React from 'react'

const Aboutus = () => {
  return (
    <section className="probootstrap-section probootstrap-bg" id="aboutus" data-section="groom-bride">
    <div className="container">
      <div className="row">
        <div className="col-md-12 probootstrap-groom-bride">
          
          <div className="probootstrap-groom probootstrap-animate">
            <figure>
              <img src="/template/img/ferlin/4.jpg" alt="Free Bootstrap Template by uicookies.com"
               className="img-responsive"/>
            </figure>
            <span className="probootstrap-caption">The Bride</span>
            <h2 className="text-center">Ferlin</h2>
            <p>Ferlin, an out-going, entrepreneurial. Born in Bandung, Indonesia in 1988, due to 
              Hong Kong- Chinese- Dutch heritance. Since early age she was already passionate in
              Art, she won her 1st international art awards in Tokyo, Japan when she was 4 years 
              old then made her as a professional artist. She became art ambassador for UNESCO, 
              United Nations, Louis Vuitton, received 195 times art awards internationally, notably 
              her artworks collected by National Museum of Norway, National Museum of China, Royal 
              family, worldwide leaders. After finished her university in China, her career brings her 
              to The Netherlands in 2010 as a residence artist for Indonesia Embassy in Den Haag.
              In 2017, she received a special permit from the Dutch Government as the start-up visa,
              in 2018 she was top 5 ethnic Business Woman of The Netherlands. Currently she is running
              her jewelry company <a href="https://www.fyfinejewelry.com" target="_blank" rel="noopener noreferrer">
              www.fyfinejewelry.com</a> and lives together with Pieter in Rotterdam 
              since 2017.</p>
              <p> Pieter is just like a faith to Ferlin, as Pieter behaviour and mindset are 
              combination like Ferlin’s parents that have rested perfectly in heaven.  </p>
            
          </div>

          <div className="probootstrap-divider probootstrap-animate"><span>&amp;</span></div>

          <div className="probootstrap-bride probootstrap-animate">
            <figure>
              <img src="/template/img/ferlin/3.jpg" alt="Free Bootstrap Template by uicookies.com"
               className="img-responsive"/>
            </figure>
            <span className="probootstrap-caption">The Groom</span>
            <h2 className="text-center">Pieter</h2>
              <p>Pieter is a persistent, entrepreneurial, sport lover, open minded, and a curious
                person. Pieter was born in 1987 in Heemstede, the Netherlands.</p>
                <p> He has big interest 
                and very knowledge-able about National Geographic, any kind of History, that makes
                him kinda a walking Wikipedia. Pieter also loves tennis a lot and he enjoys to play
                tennis on his spare time.</p>
                <p> He was raised in Laren and studied business in Erasmus
                Rotterdam University. After that he worked at a Mutual fund Robeco and a private
                equity firm.</p>
                <p> Coming from an entrepreneurial family. Then he decided to become an
                entrepreneur himself and now he is CEO of his own company with 8 employees,
                  helping other entrepreneurs to get funded by investors &nbsp;
                  <a href="https://www.investorready.nl" target="_blank" rel="noopener noreferrer">
                  www.investorready.nl</a></p>
            <p>Family is very important to Pieter, he is looking forward for the marriage life with
               Ferlin.</p>
          </div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default Aboutus
