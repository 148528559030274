import React from 'react'
import navConfig from "../Config/navConfig"
const Nav = () => {
  const navigation = navConfig.map((value,index)=>{
    return <li key={index}><a href="d" data-nav-section={value.link}>{value.name}</a></li>
  })
  return (
    <nav className="navbar navbar-default probootstrap-navbar">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false" aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand" href="index.html" title="uiCookies:Wedding">Ferlin<em>&amp;</em>
          Pieter</a>
        </div>

        <div id="navbar-collapse" className="navbar-collapse collapse">
          <ul className="nav navbar-nav navbar-right">
            <li className="active"><a href="d" data-nav-section="home">Home</a></li>
            {navigation}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav
