import React from 'react'

const OurStory = () => {
  return (
    <section className="probootstrap-section" data-section="our-story">
    <div className="container">
      <div className="row" id="ourstory">
      <div className="col">  
            <h3>Our Story</h3>
                <p>
                On a warm day during spring time 2016 under the sunset of Rotterdam,
                Ferlin went to the Nhow Hotel sky bar to meet her client. Sunset came in 
                and she took the opportunity to take beautiful sunset photos from the balcony
                facing Rotterdam city skyline. At the same time, Pieter arrived at the sky bar 
                balcony after meeting a friend. He saw Ferlin taking pictures. His curiosity in
                her made him taking the  initiative to talk to Ferlin. Pieter said, “Would you
                like me to take your picture with the sunset?”
                </p>
                <p>
                After that, they went to the lounge to enjoy talking to each other by enjoying the
                sunset and a nice cocktail. During their talk, they realized that although they are
                coming from different parts of the planet, they have very much in common:
                similarities in their background, mindset towards life, business family. It 
                resulted in a click and time. Afterwards Pieter and Ferlin did a walk through 
                the park near Hotel New York and had their first kiss. The week after they had
                their first date, then 3 dates after they had started their relationship. Although
                they had their struggles of spending time apart from each other, because of 6
                months long distance. They managed to keep together and built a strong 
                foundation.    
                </p>
                <p>
                2.5 years later of their first encounter they were walking again at the same park 
                near Hotel New York during sunset.  While suddenly Pieter went on his knees and popped
                the question. Ferlin said YES! Then, our Love story to Be Continued at our Wedding……
                </p>
            </div>
    </div>
    </div>
    </section>
  )
}

export default OurStory
