import React from 'react'

const Footer = () => {
  return (
    <footer className="probootstrap-footer">
      <div className="container text-center">
        <div className="row">
          <div className="col-md-12">
            Copyright &copy; {new Date().getFullYear()} &nbsp;
            <a href="http://www.codematrix.nl/" target="_blank" rel="noopener noreferrer">
              Code Matrix Zone</a>. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
